import React, { useCallback, useState } from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/contact.scss"

export default function Contant() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSend = useCallback(e => {
    fetch("https://api.catsh.app/index.php/v1/contact", {
      method: "post",
      body: new FormData(e.target),
    })
      .then(res => res.json())
      .then(({ success }) => success && e.target.reset())
      .catch(err => console.log({ err }))
  }, [])

  return (
    <Layout>
      <div className="contact">
        <StaticImage
          className="tree-left"
          src="../images/tree-left.png"
          alt="tree-left"
        />
        <StaticImage
          className="tree-right"
          src="../images/tree-right.png"
          alt="tree-right"
        />
        <StaticImage
          className="contact-img"
          src="../images/contact.png"
          alt="contact"
        />
        <h1>Contact Us</h1>
        <p>
          If you have any questions or suggestions - we would love to hear from
          you. Submit your details below and we will get back to you ASAP.
        </p>
        <form onSubmit={handleSend}>
          <div className="info">
            <label>
              Name
              <input
                pattern="[\p{L}]{2,}"
                required
                value={name}
                type="text"
                name="fullname"
                id="name"
                title="Name must contain at least 2 characters"
                onChange={e => setName(e.target.value)}
              />
            </label>
            <label>
              Email address *
              <input
                type="email"
                name="emailaddress"
                id="email"
                required
                title="email not valid"
                pattern="(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </label>
          </div>
          <label>
            Message
            <textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              name="message"
              id="message"
              rows="5"
            />
          </label>
          <button type="submit">Submit Details</button>
        </form>
      </div>
    </Layout>
  )
}
